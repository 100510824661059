
export const env = {

  publicFolderUrl: 'https://trialline.net/api/',
  apiUrl: 'https://trialline.net/api/v1/',
  clioApiUrl: 'https://api.trialline.net/v1/',
  appKeyClio: 'w8LD97w4zm0oqED5fvaPgYVoNsXi1fQQimjz2RmW',
  proxyUrl: 'https://trialline.net/proxy?url=',
  publicImages: 'https://trialline.net/content/',
  applicationUrl: 'https://trialline.net/',
  stripeToken: 'pk_live_pR7zvq59HrRRSNDHa2UGhqBJ',
  
  ts_key:'AKIAQUM2S3YBZJ7TMMDC',
  ts_classified:'N2Ic6n4/PR8K+H78fR8ZUtG79SaQbjjDgbrK/mvd',
  ts_locality:'us-west-2',
  ts_can:'trialline-prod',
  productFruitkey:'I4IZ7QJ9c1l9xI2O',
  prosperStackClientID:'acct_w0L72MfIEifEN8U7nQe7nEth'

};

export const permissions = {

  null: false,
  trial: {
    triallines: 2,
    users: false,
    offline: false,
    support: false
  },
  basic: {
    triallines: true,
    users: 5,
    offline: true,
    support: true
  },
  small: {
    triallines: true,
    users: 5,
    offline: true,
    support: false
  },
  large: {
    triallines: true,
    users: 20,
    offline: true,
    support: true
  }
};

